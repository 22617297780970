<template>
  <DefaultTemplate :showStep="formFlag">
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info-box">
            <p>{{ $t('deposit.cc.header') }}</p>
            <DepositInfo :instData="instData" :showNote="false"></DepositInfo>
          </div>
        </div>
        <div class="form_main">
          <el-form label-position="top" :model="depositForm" ref="depositForm" status-icon :rules="depositRules">
            <div class="form-box">
              <ul class="clearfix">
                <li>
                  <AccountNumber
                    :supportedCurrenciesList="validCurrencies"
                    :disabled="solidPayments.showAppleBtn"
                    @setCurrency="setCurrency"
                    @setAccountNumber="setAccountNumber"
                  ></AccountNumber>
                </li>
                <li>
                  <el-form-item :label="$t('common.field.amt')" prop="amount">
                    <numeric-input
                      v-model="depositForm.amount"
                      :currency="accountCurrency"
                      :readOnly="solidPayments.showAppleBtn"
                      :precision="2"
                    ></numeric-input>
                  </el-form-item>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
                    <el-input
                      v-model="depositForm.notes"
                      :disabled="solidPayments.showAppleBtn"
                      data-testid="imptNotes"
                    ></el-input>
                  </el-form-item>
                </li>
              </ul>
            </div>
            <el-form-item>
              <el-button
                class="btn-blue"
                :loading="loading"
                :disabled="loading"
                v-if="!solidPayments.showAppleBtn"
                @click="submitForm()"
                data-testid="submit"
              >
                {{ $t('common.button.submit') }}
              </el-button>
            </el-form-item>
          </el-form>
          <GeneralDialog :visible.sync="showDialog">
            <template v-slot:header>
              <h2 class="text-center dialog_title">{{ $t('deposit.applePay.payDetails') }}</h2>
            </template>
            <template>
              <div class="mt-10 dialog_content">
                <el-form>
                  <el-form-item :label="$t('common.field.accNum')"
                    ><p>{{ `${depositForm.accountNumber} - ${accountCurrency}` }}</p></el-form-item
                  >
                  <el-form-item :label="$t('common.field.amt')">
                    <p>{{ depositForm.amount }}</p>
                  </el-form-item>
                  <el-form-item :label="$t('common.field.imptNotes')">
                    <p>{{ depositForm.notes }}</p></el-form-item
                  >
                </el-form>
              </div>
              <div class="form_button text-center">
                <form
                  :action="solidPayments.shopperResultUrl"
                  class="paymentWidgets"
                  data-brands="APPLEPAY"
                ></form></div
            ></template>
          </GeneralDialog>
        </div>
      </div>
      <div class="form-right creditcard">
        <div class="logo cc ccApplePay"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import mixin from '@/mixins/page/deposit';
import { apiCc_solid_order } from '@/resource';

export default {
  name: 'appleSolidpay',
  components: { NumericInput, DefaultTemplate, AccountNumber, DepositInfo, GeneralDialog },
  props: { resultFlag: Boolean },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit })));
      } else {
        callback();
      }
    };
    return {
      depositForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      depositRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        notes: [
          {
            pattern: /^.{0,256}$/,
            message: this.$t('common.formValidation.note256'),
            trigger: 'blur'
          }
        ]
      },
      formFlag: true,
      validCurrencies: ['USD', 'USC', 'EUR', 'GBP'],
      minLimit: 1,
      maxLimit: 10000,
      showDialog: false,
      solidPayments: { shopperResultUrl: '', jsUrl: '', showAppleBtn: false },
      currencyMinLimit: {
        USD: 50,
        USC: 5000,
        EUR: 50,
        GBP: 40
      },
      currencyMaxLimit: {
        USD: 10000,
        USC: 1000000,
        EUR: 8800,
        GBP: 7600
      },
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.applePay.inst1'),
        this.$t('deposit.applePay.inst2'),
        this.$t('deposit.reminder.inst6')
      ]
    };
  },
  watch: {
    'depositForm.accountNumber': {
      immediate: true,
      handler(val) {
        this.minLimit = this.currencyMinLimit[this.accountCurrency];
        this.maxLimit = this.currencyMaxLimit[this.accountCurrency];
      }
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.depositForm.accountNumber = accountNumber;
    },
    submitDeposit() {
      return apiCc_solid_order(
        {
          mt_account: this.depositForm.accountNumber,
          operate_amount: this.depositForm.amount,
          important_notes: this.depositForm.notes
        },
        this.token
      );
    },
    submitForm() {
      this.$refs['depositForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data && result.data.code == 0) {
                const data = result.data.data;
                this.solidPayments.shopperResultUrl = data.shopperResultUrl;
                this.solidPayments.jsUrl = data.jsUrl;

                this.insertApplePayJs(this.solidPayments.jsUrl);
                this.solidPayments.showAppleBtn = true;
                this.showDialog = true;
              } else {
                this.errorMessage(this.$t('deposit.default.failed'));
                // re-calling anti-reuse token
                this.fetchToken()
                  .then(resp => {
                    this.loading = false;
                  })
                  .catch(resp => {
                    this.loading = false;
                    this.errorMessage(this.$t('deposit.default.failed'));
                  });
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        }
      });
    },
    insertApplePayJs(src) {
      const maxCounterLimit = 10;
      let counter = 1;
      let tag = document.createElement('script');
      tag.src = src;
      document.head.appendChild(tag);
      tag.onload = function () {
        let intervalID = setInterval(() => {
          if (counter === maxCounterLimit) clearInterval(intervalID);
          if (wpwlOptions) {
            wpwlOptions.applePay.onPaymentAuthorized = function (payment) {
              return {
                status: 'SUCCESS'
              };
            };
            wpwlOptions.onError = function (error) {
              // check if shopper payed after 30 minutes aka checkoutid is invalid
              if (error.name === 'InvalidCheckoutIdError') {
                //doSomething();
              } else if (error.name === 'WidgetError') {
                console.log('here we have extra properties: ');
                console.log(error.brand + ' and ' + error.event);
              }
              // read the error message
              console.log(error.message);
              console.log(error);
            };
            clearInterval(intervalID);
          }
          counter++;
        }, 1000);
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
